<template>
  <div class="page cs">
    <div class="page-header">
      <h1 class="page-header__title">
        {{ $__t("C/S") }}
      </h1>
      <p class="page-header__explanatory">
        <span>* {{ $__t("평일 10:00 ~ 17:30까지 운영합니다.") }}</span
        ><br />
        <span>/ {{ $__t("점심시간 12:00 ~ 13:30") }}</span
        ><br />
        <span>* {{ $__t("토, 일요일 및 공휴일은 휴무입니다.") }}</span>
      </p>
    </div>

    <div class="page-content">
      <ul class="button-list">
        <li
          v-button
          @click="$analytics.logEvent('snb click', { menu: 'call' })"
          class="button-list__item"
        >
          <a href="tel:+82-218779727">
            <div class="card">
              <span class="text">1877-9727</span>
              <img alt="cs phone" src="/img/icon-cs-phone.png" />
            </div>
          </a>
        </li>
        <li v-button @click="openChannelTalk" class="button-list__item">
          <div class="card">
            <span class="text">1:1 {{ $__t("채팅") }}</span>
            <img alt="cs phone" src="/img/icon-cs-talk.png" />
          </div>
        </li>
      </ul>

      <ul class="menu-list">
        <li class="menu-list__item">
          <router-link :to="{ name: 'CsFaq' }">
            <div class="menu-item">
              <div class="icon">
                <img src="@/assets/icons/svg/question-balloon.svg" />
              </div>
              <div class="text">
                {{ $__t("자주 찾는 질문") }}
              </div>
              <div class="arrow">
                <i class="fas fa-angle-right"></i>
              </div>
            </div>
          </router-link>
        </li>

        <li class="menu-list__item">
          <router-link :to="{ name: 'CsPolicy' }">
            <div class="menu-item">
              <div class="icon">
                <img src="@/assets/icons/svg/setting.svg" />
              </div>
              <div class="text">
                {{ $__t("약관 및 정책") }}
              </div>
              <div class="arrow">
                <i class="fas fa-angle-right"></i>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openChannelTalk() {
      this.$analytics.logEvent("snb click", { menu: "live chat" });
      this.$emit("close-sidebar");

      if (this.wni.isNative) {
        return this.wni.execute("wnPopupOpen", { url: `https://lugstay.channel.io` });
      }

      location.href = 'https://lugstay.channel.io';
      return;
      // return this.$channelTalk.openChat();
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  &.cs {
    @include fill-viewport-min-height(#{unit(56)});
    max-width: $max-content-width;
    margin: auto;
    background: $color-white;
    padding: unit(12) unit(16);

    .page-header {
      display: flex;
      flex-direction: column;
      padding: 0;

      &__title {
        font-weight: 500;
        font-size: unit(24);
        line-height: 1.42;
        letter-spacing: -0.006em;
      }

      &__explanatory {
        margin-top: unit(8);
        font-weight: 400;
        font-size: unit(14);
        line-height: 1.43;
        color: #61676c;
        letter-spacing: -0.006em;
      }
    }

    .page-content {
      .button-list {
        margin-top: unit(40);

        &__item {
          border-radius: unit(20);

          .card {
            height: unit(77);
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #f8fafb;
            border-radius: unit(20);
            padding: unit(20) unit(16);
            cursor: pointer;

            .text {
              font-weight: 500;
              font-size: unit(14);
              line-height: 1.43;
              color: $color-black;
            }

            img {
              width: unit(39);
              height: auto;
              object-fit: contain;
            }
          }

          & + .button-list__item {
            margin-top: unit(10);
          }
        }
      }

      .menu-list {
        margin-top: unit(20);

        &__item {
          .menu-item {
            display: flex;
            align-items: center;
            gap: unit(10);
            width: 100%;
            height: unit(56);

            .icon {
              flex: 0 0 unit(24);
              width: unit(24);
              height: unit(24);

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                top: unit(-1);
              }
            }

            .text {
              flex: 1;
              font-weight: 400;
              font-size: unit(14);
              line-height: 1.43;
              letter-spacing: -0.006em;
            }

            .arrow {
              flex: 0;
              font-size: unit(24);
              -webkit-text-stroke: unit(2) $color-white;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
